@import "../../../data/styles.css";

.footer {
	width: 100%;

	display: flex;
	padding-top: 25px;
	margin-top: 50px;
	padding-bottom: 25px;
	align-items: center;
	justify-content: center;

}

.footer-credits-text {
	color: var(--tertiary-color);
	font-size: 14px;
}